import EngineStateComponent from '@/components/engine-state/EngineStateComponent.vue'

export default {
  name: 'EngineStateReportComponent',
  components: {
    EngineStateComponent
  },
  props: {
    _id: {
      type: String,
      required: false,
      default: () => { return 'engineEstateReportComponent' }
    },
    sector: {
      type: String,
      required: false,
      default: () => { return null }
    }
  },
  data: () => ({
  }),
  created () {
  },
  async mounted () {},
  computed: {},
  methods: {}
}
